.menu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.menu > form {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.menu > form input {
  width: 100%;
  height: 40px;
  font-size: 16px;
  border: none;
  background: transparent;
  border: 1px solid #cccccc3a;
  padding: 0 10px;
  border-radius: 5px;
  outline: none;
  color: #ccc;
}

.users_list {
  width: 100%;
  height: calc(100% - 150px);
  overflow: hidden;
  overflow-y: auto;
}

.users_list li {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #cccccc3a;
  gap: 10px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.users_list li:hover {
  background-color: #cccccc1a;
}

.users_list li h1 {
  inline-size: 100%;
  font-size: 16px;
  color: #f0eaea;
  font-weight: normal;
}

.users_list li p {
  inline-size: 100%;
  display: flex;
  justify-content: space-between;
  color: #ccc;
}

.users_list li p span {
  font-size: 14px;
}

.users_list li p i {
  font-size: 14px;
  font-style: normal;
}

.menu_action {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px solid #cccccc21;
}

.menu_action button {
  width: 45px;
  height: 45px;
  border: none;
  background: transparent;
  font-size: 30px;
  color: #ccc;
  cursor: pointer;
}
