.chat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat_header {
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid #cccccc3a;
}

.chat_header__info {
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: #f0eaea;
}

.chat_header__info h1 {
  font-size: 24px;
  letter-spacing: 1px;
}

.chat_header__info span {
  font-size: 14px;
  color: #f0eaeaa7;
}

.chat_header__action {
  display: flex;
  align-items: center;
  gap: 40px;
}

.chat_header button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  color: #f0eaeaa7;
  cursor: pointer;
}

/* =========  chat_body =========== */
.chat_body {
  width: 100%;
  height: calc(100% - 135px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
  overflow: auto;
}

.chat_msg_box {
  display: flex;
}

.chat_msg_box.left {
  justify-content: flex-start;
}

.chat_msg_box.right {
  justify-content: flex-end;
}

.chat_msg_box p {
  max-width: 420px;
  background: #333;
  padding: 15px 20px;
  border-radius: 15px;
  line-height: 150%;
  color: #f0eaea;
  display: flex;
  flex-direction: column;
}

.chat_msg_box p i {
  inline-size: 100%;
  text-align: end;
  padding-top: 20px;
  font-style: normal;
  font-size: 14px;
  color: #f0eaea8a;
}

/* ======== chat_send ======= */
.chat_send {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-top: 1px solid #cccccc3a;
}

.chat_send input {
  flex: 1;
  height: 100%;
  font-size: 16px;
  padding: 0 20px;
  border: none;
  background: transparent;
  outline: none;
  color: #f0eaea;
  letter-spacing: 1px;
}

.chat_send button {
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #f0eaea;
  cursor: pointer;
}
