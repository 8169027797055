* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Popins, sans-serif;
  user-select: none;
  overscroll-behavior: none;
  -webkit-tap-highlight-color: transparent;
  letter-spacing: 0.05rem;
}
